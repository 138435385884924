import React, { Component } from 'react';
import { LeafletConsumer } from 'react-leaflet';
import L from "leaflet";
L.esri = require('esri-leaflet');

class Dybdekort extends Component {

    depthMap = L.esri.dynamicMapLayer({
        url: 'https://gis.aalborg.dk/akbms/rest/services/Limf/Limf_Dybde/MapServer',
        maxZoom: 18,
        //opacity: 0.6,
    })

    setContext(context) {
        this.context = context;
    }

    componentDidMount() {
        this.depthMap.addTo(this.context.map);
    }

    componentWillUnmount() {
        this.context.map.removeLayer(this.depthMap)
    }

    render() {
        return (
        <LeafletConsumer>
            {context => this.setContext(context)}
        </LeafletConsumer>
        )
    }
}

export default Dybdekort;