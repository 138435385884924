import React, { Component } from 'react';
import LayerPicker from './UIComponents/LayerPick/LayerPicker';
import Layer from './UIComponents/LayerPick/Layer'
import Fredningszoner from './Features/Fredningszoner';
import CertificeretOvernatning from './Features/CertificeretOvernatning'
import Parking from './Features/Parking'
import Fiskelinjer from './Features/Fiskelinjer'
import BoatRentals from './Features/BoatRentals';
import LocationMarker from "./UIComponents/LocationMaker";

import Fiskespots from './Features/Fiskespots'
import Shelters from './Features/Shelters';
import Shops from './Features/Shops';
import TouristOffices from './Features/TouristOffices';
import Creeks from './Features/Creeks';

const labels = {
    spots: {
        da: "Fiskepladser",
        en: "Fishingsspots",
        de: "Angeln Spots",
    },
    accomodation: {
        da: "Overnatning",
        en: "Accommodation",
        de: "Unterkunft",
    },
    boatRental: {
        da: "Bådudlejning",
        en: "Boat rentals",
        de: "Bootsverleih",
    },
    gearShops: {
        da: "Grejbutikker",
        en: "Angling shops",
        de: "Angeln Shops",
    },
    shelters: {
        da: "Lejrpladser",
        en: "Shelters",
        de: "Schutzhütten",
    },
    touristOffices: {
        da: "Turistbureau",
        en: "Touristoffice",
        de: "Touristenbüros",
    },
    streams: {
        da: "Vandløb",
        en: "Streams",
        de: "Flieβgewässer",
    }
}

class MapLayers extends Component {
    // constructor(props){
    //     super(props)
    // }
    state = {
            showSpots: true,
            showHotels: false,
            showBoats: false,
            showCamps: false,
            showShops: false,
            showTourists: false,
            showCreeks: false,
    }

    render() {
        return (
            <div>
                <LayerPicker>
                    <div style={{display: this.props.display || "none"}}>
                    <Layer className="layerPicker" text={labels.spots[this.props.language]} icon={labels.spots.da} active={this.state.showSpots} onClick={() => this.setState({showSpots: !this.state.showSpots})}/>
                    <Layer className="layerPicker" text={labels.accomodation[this.props.language]} icon={labels.accomodation.da} active={this.state.showHotels} onClick={() => this.setState({showHotels: !this.state.showHotels})}/>
                    <Layer className="layerPicker" text={labels.boatRental[this.props.language]} icon={labels.boatRental.da} active={this.state.showBoats} onClick={() => this.setState({showBoats: !this.state.showBoats})}/>
                    <Layer className="layerPicker" text={labels.gearShops[this.props.language]} icon={labels.gearShops.da} active={this.state.showShops} onClick={() => this.setState({showShops: !this.state.showShops})}/>
                    <Layer className="layerPicker" text={labels.shelters[this.props.language]} icon={labels.shelters.da} active={this.state.showCamps} onClick={() => this.setState({showCamps: !this.state.showCamps})}/>
                    <Layer className="layerPicker" text={labels.touristOffices[this.props.language]} icon={labels.touristOffices.da} active={this.state.showTourists} onClick={() => this.setState({showTourists: !this.state.showTourists})}/>
                    <Layer className="layerPicker" text={labels.streams[this.props.language]} icon={labels.streams.da} active={this.state.showCreeks} onClick={() => this.setState({showCreeks: !this.state.showCreeks})}/>
                    </div>
                </LayerPicker>


                {this.props.showLines && <Fiskelinjer language={this.props.language} />}
                {this.props.showFredning && <Fredningszoner language={this.props.language} />}
                {this.props.showParking && <Parking data={this.props.spots} language={this.props.language} />}
                {this.props.yourLocation && <LocationMarker yourLocation={this.props.yourLocation} language={this.props.language} />}

                {this.state.showSpots && <Fiskespots onClick={this.props.selectSpot} filteredSpots={this.props.filteredSpots || []} language={this.props.language} />}
                {this.state.showHotels && <CertificeretOvernatning onClick={this.props.selectSidebar} language={this.props.language} />}
                {this.state.showBoats && <BoatRentals onClick={this.props.selectSidebar} language={this.props.language} />}
                {this.state.showCamps && <Shelters language={this.props.language} />}
                {this.state.showShops && <Shops onClick={this.props.selectSidebar} language={this.props.language} />}
                {this.state.showTourists && <TouristOffices language={this.props.language} />}
                {this.state.showCreeks && <Creeks language={this.props.language} />}

            </div>
        );
    }
}

export default MapLayers;