import React, { Component } from "react";
import "./SpotDescription.scss";
import windIcon from "../../Icons/windArrowSmall.svg";
import moment from "moment";
import { Icon } from "antd";

const labels = {
  coastType: {
    da: "Kysttype",
    de: "Küstentyp",
    en: "Coastal type"
  },
  fishTypes: {
    da: "Fiskearter",
    de: "Fischarten",
    en: "Fish species"
  },
  windDirection: {
    da: "Optimal vindretning",
    de: "Optimale Windrichtung",
    en: "Optimal wind direction"
  },
  season: {
    da: "Sæson",
    de: "Saison",
    en: "Season"
  },
  months: {
    da: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec"
    ],
    de: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez"
    ],
    en: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ]
  },
  winddirs: {
    da: ["N", "NØ", "Ø", "SØ", "S", "SV", "V", "NV"],
    en: ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
    de: ["N", "NO", "O", "SO", "S", "SW", "W", "NW"]
  },
  seasons: {
    high: {
      da: "Højsæson",
      de: "Hochsaison",
      en: "High season"
    },
    middle: {
      da: "Mellemsæson",
      de: "Zwischensaison",
      en: "Mid season"
    },
    low: {
      da: "Lavsæson",
      de: "Nebensaison",
      en: "Low season"
    },
    protected: {
      da: "Fredet",
      de: "Geschützt",
      en: "Protected"
    }
  },
  navgigateTo: {
    da: "Naviger til fiskeplads",
    de: "Zum Angelplatz navigieren",
    en: "Navigate to fishing spot"
  }
};

class SpotDescription extends Component {
  constructor(props) {
    super(props);
    this.isHigh = this.isHigh.bind(this);
    this.isMiddle = this.isMiddle.bind(this);
    this.isProtected = this.isProtected.bind(this);
    this.isFirstMonthProtected = this.isFirstMonthProtected.bind(this);
    this.isLastMonthProtected = this.isLastMonthProtected.bind(this);
    this.videoPlayer = React.createRef();

    this.state = {
      videoPaused: false,
      videoMuted: false,
      videoFullscreen: false
    };

    this.renderVideoPlayer = this.renderVideoPlayer.bind(this);
  }

  componentDidMount() {
    //console.log(this.props.spot);
  }

  winddirs = ["N", "NØ", "Ø", "SØ", "S", "SV", "V", "NV"];
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Maj",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dec"
  ];

  isHigh = index => {
    if (this.props.spot.id === 14) {
      console.log(this.props.spot);
    }
    return this.props.spot.seasons.some(s => {
      let date = moment("14/" + (index + 1), "DD/MM");
      let start = moment(s.start, "DD/MM").year(date.year());
      let end = moment(s.end, "DD/MM").year(date.year());

      if (end.isAfter(start)) {
        return date.isBetween(start, end) && s.type === "high";
      } else {
        return !date.isBetween(end, start) && s.type === "high";
      }
    });
  };
  isMiddle = index => {
    return this.props.spot.seasons.some(s => {
      let date = moment("14/" + (index + 1), "DD/MM");
      let start = moment(s.start, "DD/MM").year(date.year());
      let end = moment(s.end, "DD/MM").year(date.year());

      if (end.isAfter(start)) {
        return date.isBetween(start, end) && s.type === "middle";
      } else {
        return !date.isBetween(end, start) && s.type === "middle";
      }
    });
  };
  isProtected = index => {
    return this.props.spot.seasons.some(s => {
      let date = moment("14/" + (index + 1), "DD/MM");
      let start = moment(s.start, "DD/MM").year(date.year());
      let end = moment(s.end, "DD/MM").year(date.year());

      if (end.isAfter(start)) {
        return date.isBetween(start, end) && s.type === "protected";
      } else {
        return !date.isBetween(end, start) && s.type === "protected";
      }
    });
  };
  isFirstMonthProtected = index => {
    return this.props.spot.seasons.some(s => {
      let date = moment("14/" + (index + 1), "DD/MM");
      let start = moment(s.start, "DD/MM").year(date.year());
      return date.month() === start.month() && s.type === "protected";
    });
  };
  isLastMonthProtected = index => {
    return this.props.spot.seasons.some(s => {
      let date = moment("14/" + (index + 1), "DD/MM");
      let end = moment(s.end, "DD/MM").year(date.year());
      return date.month() === end.month() && s.type === "protected";
    });
  };

  navigateTo = el => {
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
        el.gps[0].lat +
        "," +
        el.gps[0].long
    );
  };

  renderVideoPlayer() {
    const {
      videoPaused,
      videoLoading,
      videoMuted,
      videoHovered,
      pauseHovered,
      muteHovered,
      videoFullscreen
    } = this.state;
    const { currentTime, duration, muted } = this.videoPlayer;
    const { language, spot } = this.props;

    return (
      <div
        style={{
          marginBottom: 20,
          position: videoFullscreen ? "fixed" : "relative",
          top: videoFullscreen ? "0" : "auto",
          bottom: videoFullscreen ? "0" : "auto",
          left: videoFullscreen ? "0" : "auto",
          right: videoFullscreen ? "0" : "auto",
          zIndex: 2000
        }}
      >
        <video
          ref={ref => (this.videoPlayer = ref)}
          style={{ width: "100%" }}
          controls
          onClick={() => {
            this.videoPlayer.paused
              ? this.videoPlayer.play()
              : this.videoPlayer.pause();
            this.setState({
              videoPaused: this.videoPlayer.paused
            });
          }}
          onTimeUpdate={() => {
            this.setState({
              currentTime: currentTime
            });
          }}
        >
          <source
            src={spot["video" + (language === "da" ? "" : "_" + language)]}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        {videoPaused && false && (
          <VideoComponent
            width={50}
            height={50}
            position="center"
            onClick={() => {
              this.videoPlayer.paused
                ? this.videoPlayer.play()
                : this.videoPlayer.pause();
              this.setState({
                videoPaused: this.videoPlayer.paused
              });
            }}
          >
            <Icon type="caret-right" style={{ display: "flex" }} />
          </VideoComponent>
        )}
        {videoLoading && false && (
          <VideoComponent width={50} height={50} position="center">
            <span>•••</span>
          </VideoComponent>
        )}
        {(videoHovered || pauseHovered || muteHovered) &&
          !videoPaused &&
          !videoLoading &&
          false && (
            <VideoComponent
              onMouseEnter={() => this.setState({ pauseHovered: true })}
              onMouseLeave={() => this.setState({ pauseHovered: false })}
              width={50}
              height={50}
              position="center"
              onClick={() => {
                this.videoPlayer.paused
                  ? this.videoPlayer.play()
                  : this.videoPlayer.pause();
                this.setState({
                  videoPaused: this.videoPlayer.paused
                });
              }}
            >
              <Icon type="pause" style={{ display: "flex" }} />
            </VideoComponent>
          )}
        {(videoHovered || pauseHovered || muteHovered) && false && (
          <button
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              fontSize: "20px",
              color: videoMuted ? "#AAA" : "#FFF",
              border: 0,
              width: 40,
              height: 40,
              outline: "none",
              cursor: "pointer",
              background: "#222",
              opacity: 0.8,
              borderRadius: 9999,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            onClick={() => {
              this.videoPlayer.muted = !muted;
              this.setState({ videoMuted: this.videoPlayer.muted });
            }}
            onMouseEnter={() => this.setState({ muteHovered: true })}
            onMouseLeave={() => this.setState({ muteHovered: false })}
          >
            <Icon style={{ display: "flex" }} type="sound" />
          </button>
        )}

        {(videoHovered || pauseHovered || muteHovered) && false && (
          <VideoComponent
            width={40}
            height={40}
            position="top-left"
            onMouseEnter={() => this.setState({ pauseHovered: true })}
            onMouseLeave={() => this.setState({ pauseHovered: false })}
            onClick={() => {
              //this.setState({ videoFullscreen: !videoFullscreen });
              openFullscreen(this.videoPlayer);
            }}
          >
            <Icon
              type={videoFullscreen ? "fullscreen-exit" : "fullscreen"}
              style={{ display: "flex", alignItems: "center" }}
            />
          </VideoComponent>
        )}

        {(videoHovered || pauseHovered || muteHovered) && false && (
          <div
            style={{
              position: "absolute",
              bottom: 25,
              right: 20,
              left: 20,
              fontSize: "20px",
              color: "#FFF",
              width: "auto",
              height: 10,
              outline: "none",
              cursor: "pointer",
              background: "#222",
              opacity: 0.8,
              borderRadius: 9999,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            ref={ref => (this.scrollBar = ref)}
            onClick={e => {
              var rect = e.target.getBoundingClientRect();
              var x = e.clientX - rect.left; //x position within the element.
              var y = e.clientY - rect.top; //y position within the element.
              console.log(y);
              const time = (x / rect.width) * duration;
              console.log(time);
              this.videoPlayer.currentTime = time;
            }}
            onMouseEnter={() => this.setState({ muteHovered: true })}
            onMouseLeave={() => this.setState({ muteHovered: false })}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                marginTop: -15,
                marginLeft: -15,
                left:
                  (this.state.currentTime / this.videoPlayer.duration) * 100 +
                  "%",
                height: 30,

                border: "2px solid #222",
                borderRadius: 9999,
                background: "#FFF",
                width: 30
              }}
            />
          </div>
        )}
      </div>
    );
  }

  leftMarginNow = ((moment().dayOfYear() - 1) / 365) * 100 + "%";

  render() {
    const windDirections = this.props.spot.wind.map(w => (
      <span>
        {labels.winddirs[this.props.language][w - 1]}{" "}
        <img
          className="windIcon"
          src={windIcon}
          alt="Vind icon"
          style={{ transform: "rotate(" + (135 + (w - 1) * 45) + "deg)" }}
        />{" "}
      </span>
    ));

    const fishTypes = this.props.spot.fishTypes[this.props.language].map(f => (
      <span>{f} </span>
    ));
    // console.log(this.props.spot);

    const months = labels.months[this.props.language].map((m, i) => {
      let cName = "month";
      cName += this.isHigh(i) ? " high" : "";
      cName += this.isMiddle(i) ? " middle" : "";
      cName +=
        this.isProtected(i) && !this.isFirstMonthProtected(i)
          ? " protected"
          : "";
      // console.log(cName);
      return (
        <div className={cName}>
          <div>{m}</div>
          {this.isFirstMonthProtected(i) && (
            <div className="protecFirstMonth"></div>
          )}
          {this.isLastMonthProtected(i) && (
            <div className="protecLastMonth"></div>
          )}
        </div>
      );
    });
    const { spot } = this.props;
    return (
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <div id="spotDescription">
          <div className="header">
            <div>
              <h2>{this.props.spot.name}</h2>
            </div>
            {spot.video !== "" && this.renderVideoPlayer()}
            <div className="headlineBar">
              <span className="label">
                {labels.coastType[this.props.language]}
              </span>
              <span className="data">
                {this.props.spot.coastType[this.props.language]}
              </span>
            </div>
            <div className="headlineBar">
              <span className="label">
                {labels.windDirection[this.props.language]}
              </span>
              <span
                className="directions data"
                data-bind="foreach: spot().wind"
              >
                {windDirections}
              </span>
            </div>
            <div className="fishTypes" id="fiskeTyper">
              <span className="label">
                {labels.fishTypes[this.props.language]}
              </span>
              <span className="data">{fishTypes}</span>
            </div>
          </div>
          <div>
            <b>{labels.season[this.props.language]}</b>
          </div>
          <div className="seasonbar">
            <div id="seasonContainer">
              {months}
              <div className="now" style={{ left: this.leftMarginNow }}></div>
            </div>
          </div>
          <div id="seasonDescription">
            <div>
              <div>
                <span style={{ background: "#558857" }}></span>
                {labels.seasons.high[this.props.language]}
              </div>
              <div>
                <span style={{ background: "#88a079" }}></span>
                {labels.seasons.middle[this.props.language]}
              </div>
            </div>
            <div>
              <div>
                <span style={{ background: "rgba(0,0,0,0.30)" }}></span>
                {labels.seasons.low[this.props.language]}
              </div>
              <div>
                <span style={{ background: "rgba(234, 105, 105, 1)" }}></span>
                {labels.seasons.protected[this.props.language]}
              </div>
            </div>
          </div>
          {/* <div><h4>Beskrivelse af fiskepladsen</h4></div> */}
          <p className="description">
            {this.props.spot.description[this.props.language]}
          </p>
        </div>

        <div className="buttonContainer">
          <button
            className="navButton"
            onClick={() => this.navigateTo(this.props.spot)}
          >
            {labels.navgigateTo[this.props.language]}
          </button>
        </div>
      </div>
    );
  }
}

class VideoComponent extends Component {
  render() {
    const {
      children,
      onClick,
      onMouseLeave,
      onMouseEnter,
      position,
      width,
      height
    } = this.props;

    const pos = {
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "auto"
    };

    const margin = {
      marginTop: "auto",
      marginLeft: "auto"
    };
    const padding = 10;

    switch (position) {
      case "top-left":
        pos.top = padding;
        pos.left = padding;
        break;
      case "center":
        pos.top = "50%";
        pos.left = "50%";
        margin.marginTop = -width / 2;
        margin.marginLeft = -width / 2;
        break;
      case "top-right":
        pos.top = padding;
        pos.right = padding;
        break;

      default:
        break;
    }
    return (
      <button
        style={{
          fontSize: 20,
          color: "#FFF",
          position: "absolute",
          ...pos,
          ...margin,
          background: "#222",
          opacity: 0.7,
          borderRadius: 9999,
          width,
          height,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer"
        }}
        onClick={onClick && onClick}
        onMouseEnter={onMouseEnter && onMouseEnter}
        onMouseLeave={onMouseLeave && onMouseLeave}
      >
        {children}
      </button>
    );
  }
}

function openFullscreen(elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
}

export default SpotDescription;
