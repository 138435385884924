import moment from 'moment';

const SeasonFilter = (spot, date) => {
    const isInSeason = spot.seasons.some((s) => {
        
        let start = moment(s.start,  "DD/MM").year(date.year());
        let end = moment(s.end,    "DD/MM").year(date.year());

        if(end.isAfter(start)) {
            return date.isBetween(start, end) && s.type !== "protected";
        } else {
            return !date.isBetween(end, start) && s.type !== "protected";
        }
    });

    return isInSeason;
}

const WindFilter = (spot, weather) => {

    if(weather.wind.speed < 5)
        return true;
    return spot.wind.includes(parseInt(weather.wind.deg))
};

export { WindFilter, SeasonFilter }