import React, { Component } from 'react';
import RawIcons from '../../Icons/rawIcons';
import './LayerPicker.scss';


class Layer extends Component {
    render() {
        return (
            <div style={!this.props.active ? {background: "rgba(111, 111, 111, 0.6)", fontWeight: 100} : {background: "rgba(35, 35, 36, 0.9)"}} onClick={this.props.onClick} className={this.props.className}>
                {this.props.className === "layerPicker" && <img alt='' className="pictogram" src={RawIcons[this.props.icon]}/>}
                <p>{this.props.text}</p>
            </div>

        );
    }
}

export default Layer;