import React, { Component } from 'react';
import { LeafletConsumer } from 'react-leaflet';
import L from "leaflet";
L.esri = require('esri-leaflet');

class Fiskelinjer extends Component {

  layer = L.esri.featureLayer({ //fiskelinjer
    url: 'https://gis.aalborg.dk/ak5/rest/services/MiljoePub/LIMF_Fiskepladser/MapServer/1',
    style: {color: "#03a39b", weight: 4},
  })

  setContext(context) {
    this.context = context;
  }

  componentDidMount() {
      this.layer.addTo(this.context.map);
  }

  componentWillUnmount() {
      this.context.map.removeLayer(this.layer)
  }

  render() {
      return (
      <LeafletConsumer>
          {context => this.setContext(context)}
      </LeafletConsumer>
      )
  }
}

export default Fiskelinjer;