import campIcon from './rawIcons/Camp.png';
import touristIcon from './rawIcons/visitLogo.png';
import overnatning from './rawIcons/certified.png';
import fiskepladser from './rawIcons/Fiskepladser.png';
import gear from './rawIcons/gear.png';
import boat from './rawIcons/sailing.png';
import creek from './rawIcons/creek.png';

const iconUrls = {
    Fiskepladser: fiskepladser,
    Bådudlejning: boat,
    Lejrpladser: campIcon,
    Grejbutikker: gear,
    Vandløb: creek,
    Turistbureau: touristIcon,
    Overnatning: overnatning,
};

export default iconUrls;