import React, { Component } from 'react';
import { LeafletConsumer } from 'react-leaflet';
import L from "leaflet";

L.esri = require('esri-leaflet');

const labels = {
  creek: {
    da: "Vandløb",
    en: "Stream",
    de: "Flieβgewässer",
  },
  whoFishes: {
    da: "Foreninger som fisker i dette vandløb",
    en: "Associations fishing in this stream",
    de: "Verbände, die in diesem Flieβgewässer fischen",
  },
  noOne: {
    da: "Ingen foreninger er tilknyttet dette vandløb.",
    en: "No associations are associated with this stream.",
    de: "Mit diesem Flieβgewässer sind keine Assoziationen verbunden.",
  }
}

class Creeks extends Component {
  constructor(props) {
    super(props)
    this.layer = L.esri.featureLayer({ //pladser fra gis
      url: 'https://gis.aalborg.dk/akpe/rest/services/Limf/Limf_vandloeb/FeatureServer/0',
      style: (feature)=>{
          return {
              color: '#0077be',
              opacity: 0.5,
              weight: 4
          }
      },
      simplifyFactor: 2,
    });

  }
  
  setContext(context) {
    this.context = context;
  }

  componentDidMount() {
    this.layer.on('mouseover', (e)=>{
        this.layer.setFeatureStyle(e.layer.feature.id, {color: '#5577be', opacity: 0.7, weight: 10});
    }).on('mouseout', (e)=>{
        this.layer.resetStyle(e.layer.feature.id);
        //console.log(e.layer.feature);
    }).bindTooltip((layer) => { return layer.feature.properties.VANDLØB})
        .bindPopup((layer) => { 
            let text = `<div class="creekDescription">
                        <h3>${labels.creek[this.props.language]} ${layer.feature.properties.VANDLØB} </h3>`;
                        if(layer.feature.properties.BESKRIVELSE) 
                            text += `<div>${layer.feature.properties.BESKRIVELSE}</div>`;
            if(!(layer.feature.properties.FORENING_1 || layer.feature.properties.FORENING_2 || layer.feature.properties.FORENING_3)) {
                return text + `${labels.noOne[this.props.language]}</div>`;
            }
            
            
            text += `<p style="margin: 0;">${labels.whoFishes[this.props.language]}</p>
                    <ul>`;
            
            //tilføj foreninger som findes:
            layer.feature.properties.FORENING_1 && (text += `<a href="http://${layer.feature.properties.LINK_FORENING1}"><li>${layer.feature.properties.FORENING_1}</li></a>`);
            layer.feature.properties.FORENING_2 && (text += `<a href="http://${layer.feature.properties.LINK_FORENING2}"><li>${layer.feature.properties.FORENING_2}</li></a>`);
            layer.feature.properties.FORENING_3 && (text += `<a href="http://${layer.feature.properties.LINK_FORENING3}"><li>${layer.feature.properties.FORENING_3}</li></a>`);
            text += `</ul>
                    </div>`;
            return text;
        });
    
    this.layer.addTo(this.context.map);
  }

  componentWillUnmount() {
    this.context.map.removeLayer(this.layer)
  }

  render() {
    return (
      <LeafletConsumer>
        {context => this.setContext(context)}
      </LeafletConsumer>
    )
  }
}

export default Creeks;