import React, { Component } from "react";
//import "./tailwind.css";
import "./App.css";

import { Map } from "react-leaflet";
import Sidebar from "./UIComponents/Sidebar";
import SidebarContent from "./UIComponents/SidebarContent/SidebarContent";
import SpotDescription from "./UIComponents/Spotdescription/SpotDescription";
import BestSpots from "./UIComponents/BestSpots/BestSpots";
import MapLayers from "./MapLayers";
import ShowButton from "./UIComponents/ShowButton";
import L from "leaflet";
import FilterPicker from "./UIComponents/BestSpots/FilterPicker";
import { filterSpots, spotDistanceFilter } from "./Helpers/SpotFilterer";
import { ConvertWeatherData } from "./WeatherHelper";
import MapTiles from "./MapTiles";
import DayPicker from "./UIComponents/Weather/DayPicker";
L.esri = require("esri-leaflet");

const labels = {
  all: {
    da: "Alle",
    en: "All",
    de: "Alles",
  },
};

class App extends Component {
  constructor(props) {
    super(props);

    this.selectSpot = this.selectSpot.bind(this);
    this.selectSpotFromList = this.selectSpotFromList.bind(this);
    this.selectSidebar = this.selectSidebar.bind(this);
    this.zoomHandler = this.zoomHandler.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.filterSpots = this.filterSpots.bind(this);
    this.urlSpot = this.urlSpot.bind(this);

    window.addEventListener("resize", () => {
      document.body.style.height = window.innerHeight + "px";
    });

    setTimeout(() => {
      document.body.style.height = window.innerHeight + "px";
    }, 100);
  }

  state = {
    lat: 56.85,
    lng: 9.4,
    zoom: 9,
    showBestSpots: false,
    spots: [],
    distanceFiltered: false,
    showParking: false,
    showFredning: false,
    showLines: false,
    clearScreen: false,
    settings: {
      windSelected: true,
      seasonSelected: true,
      fishType: "Alle",
      coastType: "Alle",
    },
    language: "da",
  };

  async componentDidMount() {
    const spotsJson = await fetch(
      "https://www.limfjordsraadet.dk/data/fiskepladser"
    ).then((raw) => raw.json());
    const spots = spotsJson.map((s) => {
      s.coastType = {
        da: s.coastType,
        de: s.coastType_de,
        en: s.coastType_en,
      };
      s.description = {
        da: s.description,
        de: s.description_de,
        en: s.description_en,
      };
      s.fishTypes = {
        da: s.fishTypes,
        de: s.fishTypes_de,
        en: s.fishTypes_en,
      };
      //console.log(s)
      return s;
    });
    this.setState(
      {
        spots: spots,
      },
      () => {
        //console.log(this.state.spots.filter(s => s.video !== ""));
        this.filterSpots();
        this.setCategories();
        this.urlSpot();
        window.onhashchange = () => {
          this.urlSpot();
        };
      }
    );

    //hent vejret:
    let cityId = "2624886";
    let apiKey = "6af01f06672f0c7e342d34e5e2bf6fea";
    const weatherJson = await fetch(
      "https://api.openweathermap.org/data/2.5/forecast?" +
      new URLSearchParams({
        "id": cityId,
        "units": "metric",
        "APPID": apiKey,
      })
    ).then((raw) => raw.json());

    let convertedWeather = ConvertWeatherData(weatherJson, {
      from: 6,
      to: 21,
      interval: 3,
    });
    this.setState(
      { weather: convertedWeather, currentWeather: convertedWeather[0] },
      this.filterSpots
    );

    //spørg efter geolocation
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState(
          {
            yourLocation: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
          () => {
            this.filterSpots();
          }
        );
      },
      (PositionError) => console.log(PositionError)
    );

    window.addEventListener("resize", () => {
      document.body.style.height = window.innerHeight + "px";
    });

    setTimeout(() => {
      document.body.style.height = window.innerHeight + "px";
    }, 100);
  }
  urlSpot() {
    if (window.location.hash) {
      let hashArray = window.location.hash.split("#");
      hashArray.shift();
      //console.log(hashArray)
      let lang = "da";
      let spotNr;
      hashArray.forEach((h) => {
        let keyVal = h.split("=");
        if (keyVal.length === 1) spotNr = parseInt(keyVal[0]) - 1;
        else if (keyVal[0] === "lang") lang = keyVal[1];
        else lang = "da";
      });
      const settings = this.state.settings;
      settings.fishType = labels.all[lang];
      settings.coastType = labels.all[lang];

      this.setState({ language: lang, settings: settings }, () => {
        this.filterSpots();

        this.setCategories();
        if (this.state.language === "de") {
          //this.state.spots
        }
      });
      if (spotNr > -1 && spotNr < 151) {
        this.selectSpotFromList(this.state.spots[spotNr]);
      }
    }
  }

  setCategories() {
    //init categories fra fiskepladser
    let coastCategories = [
      labels.all[this.state.language],
      ...Array.from(
        new Set(this.state.spots.map((d) => d.coastType[this.state.language]))
      ),
    ];
    let fishCategories = [
      labels.all[this.state.language],
      ...Array.from(
        new Set(
          this.state.spots.reduce(
            (prev, cur) => [...prev, ...cur.fishTypes[this.state.language]],
            []
          )
        )
      ),
    ];
    //console.log(coastCategories)
    this.setState({
      coastCategories: coastCategories,
      fishCategories: fishCategories,
    });
  }

  zoomHandler(e) {
    let zoom = e.target._zoom;
    zoom > 11 && this.setState({ showLines: true });
    zoom < 12 && this.setState({ showLines: false });
    // //vis fredningsZoner når over 11 i zoom
    zoom > 10 && this.setState({ showFredning: true });
    zoom < 11 && this.setState({ showFredning: false });
    //vis/hide parkingmarkers
    zoom > 12 && this.setState({ showParking: true });
    zoom < 13 && this.setState({ showParking: false });
  }

  selectSidebar(data) {
    this.setState({ selectedSpot: null, selectedLeftSidebar: data });
    this.onOpen();
  }

  selectSpot(spot) {
    //select spot fra spot marker
    this.map.leafletElement.flyTo(spot._latlng, 13, {
      animate: true,
      duration: 0.8,
      easeLinearity: 0,
    });

    if (window.innerWidth < 800) {
      this.setState({
        showBestSpots: false,
      });
      setTimeout(() => {
        this.setState({
          selectedSpot: this.state.spots.find(
            (s) => s.id === spot.feature.properties.NY_ID
          ),
        });
      }, 800);
    } else {
      this.setState({
        selectedLeftSidebar: null,
        selectedSpot: this.state.spots.find(
          (s) => s.id === spot.feature.properties.NY_ID
        ),
      });
    }
  }

  selectSpotFromList(spot) {
    //når spot bliver selected fra bestspotList
    this.map.leafletElement.flyTo(
      { lat: spot.gps[0].lat, lng: spot.gps[0].long },
      13,
      {
        animate: true,
        duration: 0.8,
        easeLinearity: 0,
      }
    );

    if (window.innerWidth < 800) {
      this.setState({ showBestSpots: false });
      setTimeout(() => {
        this.setState({
          selectedLeftSidebar: null,
          selectedSpot: spot,
        });
      }, 800);
    } else {
      this.setState({
        selectedLeftSidebar: null,
        selectedSpot: spot,
      });
    }
  }

  onClose(side) {
    this.setState({ clearScreen: false });
    side === "left" &&
      this.setState({
        selectedSpot: null,
        selectedLeftSidebar: null,
      });

    side === "right" &&
      this.setState({
        showBestSpots: false,
      });
  }

  onOpen() {
    if (window.innerWidth < 800) {
      //hvis small, clear screen.
      this.setState({ clearScreen: true });
    }
    if (!this.state.distanceFiltered)
      //forsøg på fix ufiltrerede pladser.
      this.filterSpots();
  }

  filterSpots() {
    if (
      this.state.yourLocation &&
      !this.state.distanceFiltered &&
      this.state.spots.length > 0
    ) {
      spotDistanceFilter(this.state.spots, this.state.yourLocation);
      this.setState({ distanceFiltered: true }); //sørg for at den ikke filter distance hver gang
    }
    let filteredSpots = filterSpots(
      this.state.currentWeather,
      this.state.spots,
      this.state.futureDate,
      this.state.futureMode,
      this.state.settings,
      this.state.language
    );
    this.setState({ filteredSpots: filteredSpots });
  }

  render() {
    const position = [this.state.lat, this.state.lng];
    return (
      <div style={{ overflow: "hidden", position: "relative", height: "100%" }}>
        <Sidebar //Left Sidebar
          collapsed={
            !this.state.selectedSpot && !this.state.selectedLeftSidebar
          }
          onOpen={this.onOpen.bind(this)}
          onClose={this.onClose.bind(this)}
          position="left"
        >
          {this.state.selectedLeftSidebar && (
            <SidebarContent
              data={this.state.selectedLeftSidebar}
              language={this.state.language}
            />
          )}
          {this.state.selectedSpot && (
            <SpotDescription
              spot={this.state.selectedSpot}
              language={this.state.language}
            />
          )}
        </Sidebar>

        <Sidebar //right sidebar
          collapsed={!this.state.showBestSpots}
          onOpen={this.onOpen.bind(this)}
          onClose={this.onClose.bind(this)}
          position="right"
        >
          <BestSpots
            language={this.state.language}
            spots={this.state.filteredSpots}
            settings={this.state.settings}
            newSettings={(newSettings) => {
              this.setState({ settings: newSettings }, this.filterSpots);
            }}
            openSpot={(data) => {
              this.selectSpotFromList(data);
            }} //hvis man click'er på et spot i listen
            coastCategories={this.state.coastCategories}
            fishCategories={this.state.fishCategories}
            windSpeed={
              this.state.currentWeather
                ? this.state.currentWeather.wind.speed
                : 6
            }
            futureMode={this.state.futureMode}
          />
        </Sidebar>

        <ShowButton
          hide={this.state.clearScreen || this.state.showBestSpots}
          show={() => this.setState({ showBestSpots: true })}
          pointLeft={true}
        />
        <FilterPicker
          settings={this.state.settings}
          newSettings={(newSettings) => {
            this.setState({ settings: newSettings });
            this.filterSpots();
          }}
          display={!this.state.showBestSpots}
          coastCategories={this.state.coastCategories}
          fishCategories={this.state.fishCategories}
          windSpeed={
            this.state.currentWeather ? this.state.currentWeather.wind.speed : 6
          }
          futureMode={this.state.futureMode}
          language={this.state.language}
        />

        <Map
          ref={(ref) => {
            this.map = ref;
          }}
          center={position}
          zoom={this.state.zoom}
          minZoom={7}
          maxZoom={18}
          style={{ height: "100%" }}
          onzoomend={this.zoomHandler}
          maxBounds={[
            [57.886133, 6.292162],
            [54.423851, 14.51592],
          ]}
        >
          <MapLayers
            display={!this.state.clearScreen}
            spots={this.state.spots}
            showLines={this.state.showLines}
            showParking={this.state.showParking}
            showFredning={this.state.showFredning}
            selectSpot={this.selectSpot}
            selectSidebar={this.selectSidebar}
            filteredSpots={this.state.filteredSpots}
            yourLocation={this.state.yourLocation}
            language={this.state.language}
          />

          <MapTiles
            display={
              !(
                this.state.clearScreen ||
                this.state.showBestSpots ||
                this.state.smallWeather
              )
            }
            language={this.state.language}
          />

          <DayPicker
            display={
              !(
                this.state.selectedSpot ||
                this.state.selectedLeftSidebar ||
                this.state.clearScreen
              )
            }
            weather={this.state.weather}
            currentWeather={this.state.currentWeather}
            setSmallWeather={(val) => {
              this.setState({ smallWeather: val });
            }}
            updateCurrentWeather={(newWeather) =>
              this.setState({ currentWeather: newWeather }, this.filterSpots)
            }
            setFutureMode={(fMode, fDate) =>
              this.setState(
                { futureMode: fMode, futureDate: fDate },
                this.filterSpots
              )
            }
            language={this.state.language}
          />
        </Map>
      </div>
    );
  }
}

export default App;
