import React, { Component } from 'react';
import { Marker } from 'react-leaflet';
import { gear } from '../Icons/icons'

class Shops extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shops: []
        }
        fetch("https://www.limfjordsraadet.dk/data/fiskegrejsbutikker/").then(raw => raw.json()).then(res => {
            const data = res.map((s) => {
                s.facilities = {
                  da: s.facilities,
                  de: s.facilities_de,
                  en: s.facilities_en,
                }
                s.description = {
                  da: s.description,
                  de: s.descriptionDE,
                  en: s.descriptionEN,
                }
                return s;
            })
            this.setState({
                shops: data,
            });
        });
    }

    render() {
        const shopMarkers = this.state.shops && this.state.shops.map((x, i) => 
            <Marker 
                key={i} 
                position={{lat: x.gps[0].lat, lon: x.gps[0].long}} 
                onclick={() => this.props.onClick(x)}
                icon={gear} 
                zIndexOffset={50}/>
        )
        return (
            <div>
                {shopMarkers}
            </div>
        )
    }
}

export default Shops;