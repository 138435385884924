import spotLow from './images/features/newFishGray.png';
import spotHigh from './images/features/newFishGreen.png';
import campIcon from './images/features/camp.png';
import yourLoc from './images/features/yourLoc.png';
import parkingIcon from './images/features/Parking_icon.png';
import L from 'leaflet';
import touristIcon from './images/visitLogo.png';

import boatIcon from './images/features/boat.png'
import certifiedIcon from './images/features/certified.png'
import gearIcon from './images/features/gear.png'

const smallSize = 40;

let iconHighBig = L.icon({
    iconUrl: spotHigh,
    iconSize: [48, 48],
    iconAnchor: [32, 32]
});

let iconHighSmall = L.icon({
    iconUrl: spotHigh,
    iconSize: [smallSize, smallSize],
    iconAnchor: [20, 20]
});

let iconLowBig = L.icon({
    iconUrl: spotLow,
    iconSize: [48, 48],
    iconAnchor: [32, 32]
});

let iconLowSmall = L.icon({
    iconUrl: spotLow,
    iconSize: [smallSize, smallSize],
    iconAnchor: [20, 20]
});

let gear = L.icon({
    iconUrl: gearIcon,
    iconSize: [smallSize,smallSize]
});

let certified = L.icon({
    iconUrl: certifiedIcon,
    iconSize: [smallSize,smallSize]
});

let boat = L.icon({
    iconUrl: boatIcon,
    iconSize: [smallSize,smallSize]
});

let camp = L.icon({
    iconUrl: campIcon,
    iconSize: [smallSize,smallSize]
});

let location = L.icon({
    iconUrl: yourLoc,
    iconSize: [24,24]
});

let parking = L.icon({
    iconUrl: parkingIcon,
    iconSize: [28, 28],
});

let tourist = L.icon({
    iconUrl: touristIcon,
    iconSize: [32,32],
});




export { iconHighBig, iconHighSmall, iconLowBig, iconLowSmall, camp, location, parking, tourist, boat, gear, certified,
};