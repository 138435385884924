import React, { Component } from 'react';

class SpotCard extends Component {
  
    render() {
        const fishTypes = this.props.data.fishTypes[this.props.language].map((f,i ) => <div key={i}>{f}</div>)
        return (
            <div className="spotCard card mb-2 spotListItem" onClick={()=>{this.props.openSpot(this.props.data)}} style={this.props.selected ? selectedStyle : {}}>
                <div className="dataBar">
                    <span className="name info">{this.props.data.name}</span>
                    <span className="distance info">{this.props.data.distance && `${this.props.data.distance} km`}</span>
                    <span className="coast info">{this.props.data.coastType[this.props.language]}</span>
                    <span className="types info">
                        {fishTypes}
                    </span>
                </div>
            </div> 
        )
    }
}

const selectedStyle = {
    fontWeight: "bold",
}

export default SpotCard;
