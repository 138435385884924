import moment from 'moment';

function ConvertWeatherData(data, {from, to, interval} ) {
    let output = [];
    for(let i = 0; i<6; i++) {
        for(let time = from; time <= to; time = time+interval){
            let d = moment().add(i, 'day').hour(time);
            let day = data.list.find((element) => 
                d.isSame(element.dt_txt, 'day') && d.isSame(element.dt_txt, 'hour')
            );
            if(day) output.push(day);
        }
    }
    return mapWindDegrees(output);

}

function mapWindDegrees(data) {
    return data.map((entity) => {
        //map weather degree to 1-8 range
        let d = entity.wind.deg + 22.5;
        d > 360 && (d -= 360)
        d = Math.ceil(d/45);
        d === 0 && (d = 1);
        entity.wind.deg = d;
        return entity;
    });
}

export {ConvertWeatherData}