import React, { Component } from 'react';
import arrowLeft from '../Icons/arrowLeft.svg';
import arrowRight from '../Icons/arrowRight.svg';
import './ShowButton.scss'

class ShowButton extends Component {
    render() {
        return (
            <div id={this.props.pointLeft  ?  "bestSpotsButton" : "dayPickerButton"} onClick={this.props.show} style={{display: this.props.hide && "none"}}>
                <div className="fizzButton">
                    <img alt='' src={this.props.pointLeft ? arrowLeft : arrowRight}></img>
                </div>
            </div>
        );
    }
}

export default ShowButton;