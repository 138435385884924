import React, { Component } from 'react';
import Control from 'react-leaflet-control'
import DepthMap  from './../Icons/images/depthMap.png'
import './depthLegend.scss'
// import './MapPicker.scss'

class DepthLegend extends Component {
    state = {
    }

    componentDidMount() {
        fetch("https://gis.aalborg.dk/akbms/rest/services/Limf/Limf_Dybde/MapServer/legend?f=pjson").then(raw => raw.json()).then(res => {
            this.setState({legend: res.layers[0].legend})
        })
    }
    render() {
        // let list = this.state.legend && this.state.legend.map((l) => <div>
        //     <img alt="farve" src={"data:image/png;base64," + l.imageData} />
        //     <span>{l.label}</span>
        // </div>)
        return (
            <Control position="bottomright">
                <div className="depthLegend">
                    <img style={{height: "20>0px"}} src={DepthMap} alt="dybdekortforklaring" />
                </div>
            </Control>
        )
    }
}

export default DepthLegend;