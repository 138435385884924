import React, { Component } from 'react';
import { LeafletConsumer } from 'react-leaflet';
import Colors from '../Colors';
import ReactDOMServer from 'react-dom/server'
import L from "leaflet";
L.esri = require('esri-leaflet');

const labels = {
    allYear: {
        da: "Hele året",
        en: "All year",
        de: "Ganzjährig",
    },
    special: {
        da: "16. september - 15. marts",
        en: "September 16 - March 15",
        de: "16. September - 15. März",
    },
    protectedZone: {
        da: "Fredningszone",
        en: "Conservation Zone",
        de: "Schutzzone",
    },
    fishingProhibited: {
        da: "Fiskeri forbudt i perioden",
        en: "Fishing prohibited during the period",
        de: "Fischen während der Periode verboten",
    },
    readMore: {
        da: "Læs mere",
        en: "Read more",
        de: "Weiterlesen",
    },
    protected: {
        da: "Fredet",
        en: "Protected",
        de: "Geschützt"
    }
}

class Fredningszoner extends Component {

  layer = L.esri.featureLayer({ //fredningszoner
    url: 'https://gis.aalborg.dk/akpe/rest/services/Limf/LIMF_Fredning_Limfjorden/FeatureServer/0',
    style: (feature)=>{
        let periode = feature.properties.FREDNINGSP;
        //console.log(feature);
        //æøå problemer her....
        let fredningsColor;
        if(periode === "Hele �ret") {
            fredningsColor = Colors.fredningsRed;
            feature.properties.FREDNINGSP = labels.allYear[this.props.language];
        } else if(periode === "16. september - 15. marts") {
            fredningsColor = Colors.fredningsBlue;
            feature.properties.FREDNINGSP = labels.special[this.props.language];
        } else {
            fredningsColor = Colors.fredningsYellow;
            feature.properties.FREDNINGSP = feature.properties.FREDNINGSP.replace("�", "æ");
        }
        
        return {color: fredningsColor}
    }
    }).bindPopup((layer)=>{ //set popup på fredningszoner
        let period = layer.feature.properties.FREDNINGSP;
        let url = layer.feature.properties.WWW;
        return ReactDOMServer.renderToStaticMarkup(
            <div>
                <b>{labels.protectedZone[this.props.language]}</b>
                <br />{labels.fishingProhibited[this.props.language]}: {period} <br />
                <a href={url} target='_blank' rel="noopener noreferrer">{labels.readMore[this.props.language]}</a>
            </div>
        );            
    }).bindTooltip((layer)=>{ //set tooltip på fredningszoner
        let period = layer.feature.properties.FREDNINGSP;
        return labels.protected[this.props.language] + ": " + period;
    })

  setContext(context) {
    this.context = context;
  }

  componentDidMount() {
      this.layer.addTo(this.context.map);
  }

  componentWillUnmount() {
      this.context.map.removeLayer(this.layer)
  }

  render() {
      return (
      <LeafletConsumer>
          {context => this.setContext(context)}
      </LeafletConsumer>
      )
  }
}

export default Fredningszoner;