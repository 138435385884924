import React, { Component } from "react";
import { LeafletConsumer } from "react-leaflet";
import L from "leaflet";
L.esri = require("esri-leaflet");

class Satelite extends Component {
  map = L.esri.basemapLayer("Imagery", {
    //Lav baselayer til baggrund rundt om kort
    minZoom: 7,
    maxZoom: 18
  });

  // map = L.tileLayer(
  //   "http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
  //   {
  //     maxZoom: 18
  //   }
  // );

  //

  setContext(context) {
    this.context = context;
  }

  componentDidMount() {
    this.context.map.addLayer(this.map);
  }

  componentWillUnmount() {
    this.context.map.removeLayer(this.map);
  }

  render() {
    return (
      <LeafletConsumer>{context => this.setContext(context)}</LeafletConsumer>
    );
  }
}

export default Satelite;
