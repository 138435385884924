import React, { Component } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { camp } from '../Icons/icons'
import shelterData from '../data/shelters.json';

const labels = {
    description: {
        da: "Beskrivelse",
        en: "Description",
        de: "Beschreibung",
    },
    seeMore: {
        da: "Se flere overnatningssteder",
        en: "See more accommodations",
        de: "Weitere Unterkünfte anzeigen"
    }
}

class Shelters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            camps: shelterData,
        }
    }

    render() {
        const campMakers = this.state.camps && this.state.camps.map((x, i) => 
            <Marker 
                key={i} 
                position={x.position}
                toolTip={`Overnatning ${x.name}`} 
                icon={camp}
                zIndexOffset={1}>
                <Popup>
                    <p><b>{labels.description[this.props.language]}</b></p>
                    <p dangerouslySetInnerHTML={{__html: x.longdescription}}></p>
                    <a href='http://udinaturen.dk' target='_blank' rel='noreferrer'>{labels.seeMore[this.props.language]}</a>
                </Popup>
            </Marker>
        )
        return (
            <div>
                {campMakers}
            </div>
        )
    }
}

export default Shelters;