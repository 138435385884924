import React, { Component } from 'react';
import { Marker } from 'react-leaflet';
import { parking } from '../Icons/icons';

class Parking extends Component {

    render() {
        let markers = [];
        
        this.props.data && this.props.data.forEach((x) => 
            x.gps.forEach(y => {
            markers.push(<Marker 
                position={{lat: y.lat, lon: y.long}}
                icon={parking} />)
            })
            
        )
        return (
            <div>
                {markers}
            </div>
        )
    }
}

export default Parking;