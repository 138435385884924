import React, { Component } from 'react';
import Settings from './Settings';
import SpotCard from './SpotCard';
import './BestSpots.scss';

const labels = {
    filters: {
        da: "Filtre",
        en: "Filters",
        de: "Filter"
    },
    filteredSpots: {
        da: "Filterede fiskepladser",
        en: "Filtered fishing spots",
        de: "Gefilterte Angelplätze",
    },
    distance: {
        da: "Afstand",
        en: "Distance",
        de: "Entfernung",
    },
    coastType: {
        da: "Kysttype",
        en: "Coastal type",
        de: "Küstentyp",
    },
    fishTypes: {
        da: "Fiskearter",
        en: "Fish species",
        de: "Fischarten",
    },
    locationError: {
        da: "For at se denne liste skal du tillade lokation",
        en: "Too see this list, you have to allow location services",
        de: "Um diese Liste anzuzeigen, erlauben Sie den Standort"
    }
}

class BestSpots extends Component {
  state = {
    loading: false,
  }

  componentDidMount() {
   
  }
  
render() {
    let spotList = [];

    spotList = this.props.spots && this.props.spots.map((s, i) => 
        <SpotCard key={i} data={s} openSpot={this.props.openSpot} language={this.props.language}/>
    )

    return (
        <div className="bestSpots">
            <h3>{labels.filters[this.props.language]}</h3>   
            <Settings 
                settings={this.props.settings}
                coastTypes={this.props.coastCategories ? this.props.coastCategories : []} 
                fishTypes={this.props.fishCategories ? this.props.fishCategories : []} 
                newSettings={this.props.newSettings}
                windSpeed={this.props.windSpeed}
                futureMode={this.props.futureMode}
                language={this.props.language}  />

            <h3 id="fiskePladserHeadline">{labels.filteredSpots[this.props.language]}</h3>
            {this.state.locationError && <p>{labels.locationError[this.props.language]}</p>}
            <div>
                {this.state.loading && <i className="fas fa-3x fa-spinner fa-spin"></i>}      
                <div className="sortingBar">
                    <span>{labels.distance[this.props.language]} <i className="fas fa-angle-down"></i></span>
                    <span>{labels.coastType[this.props.language]}</span>
                    <span>{labels.fishTypes[this.props.language]}</span>
                </div>
                <div>
                    {spotList}
                </div>
            </div>
        </div>
    )
  }
}

export default BestSpots;
