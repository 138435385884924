import React, { Component } from 'react';
import {location} from '../Icons/icons'
import { Marker } from 'react-leaflet';

class LocationMaker extends Component {
    render() {
        //console.log(this.props.yourLocation);
        if(this.props.yourLocation)
            return (
                <Marker 
                    position={{lat: this.props.yourLocation.lat, lon: this.props.yourLocation.lng}} 
                    icon={location} 
                    zIndexOffset={1000}/>
            );
        else
            return null;
    }
}

export default LocationMaker;