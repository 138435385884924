import React, { Component } from 'react';
import './FilterPicker.scss'
import './BestSpots.scss';
import Settings from './Settings';

const labels = {
    filters: {
        da: "Filtre",
        en: "Filters",
        de: "Filter",
    },

}

class FilterPicker extends Component {

  
    render() {
        return (
           <div className="filterPickerContainer" style={{display: this.props.display ? "block" : "none"}}>
                <div className="smallSettingsContainer">
                    <h3>{labels.filters[this.props.language]}</h3>
                    <Settings 
                        coastTypes={this.props.coastCategories ? this.props.coastCategories : []} 
                        fishTypes={this.props.fishCategories ? this.props.fishCategories : []} 
                        settings={this.props.settings}
                        futureMode={this.props.futureMode}
                        windSpeed={this.props.windSpeed} 
                        newSettings={this.props.newSettings}
                        language={this.props.language}
                    />
                </div>
           </div>
        )
    }
}

export default FilterPicker;
