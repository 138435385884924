import React, { Component } from 'react';
import WeatherDisplay from './WeatherDisplay';
import Control from 'react-leaflet-control'
import FutureMonths from './FutureMonths';
import './DayPicker.scss';
import PickContainer from './PickContainer';
import arrowRight from '../../Icons/arrowRight.svg';
import moment from 'moment';

const labels = {
    later: {
        da: "Senere",
        en: "Later",
        de: "Bis",
    }
}

class DayPicker extends Component {

    constructor(props){
        super(props)


        this.state={
            showWhenSmall: false,
            showFuture: false,
        }
    }

    render() {


        return (
            <Control id="weatherContainer" position="bottomleft">

                <div id="weatherDayPicker" className={this.state.showWhenSmall ? "visible" : "hidden"} style={{display: this.props.display ? "block" : "none"}}>
                    {/* Knapper til mobile. */}
                    <div className="toggleButton" onClick={()=>{
                        this.setState({showWhenSmall: true});
                        this.props.setSmallWeather(true);
                    }}>
                        <img alt='' id="toggleButtonIcon" src={arrowRight} />
                    </div>
                    <div className="closeButton" onClick={()=>{
                        this.setState({showWhenSmall: false});
                        this.props.setSmallWeather(false);
                    }}>
                        <img alt='' id="closeButtonIcon" />
                    </div>

                    <WeatherDisplay futureMode={this.state.showFuture} weather={this.props.currentWeather}/>

                    {this.state.showFuture && 
                        <FutureMonths 
                            futureSwitch={()=>{
                                this.setState({showFuture: !this.state.showFuture});
                                this.props.setFutureMode(false, false);
                            }}
                            setFutureMode={this.props.setFutureMode}
                            language={this.props.language}
                        />
                    }
                    {(!this.state.showFuture && this.props.weather) &&
                        <PickContainer 
                            weather={this.props.weather} 
                            updateCurrentWeather={this.props.updateCurrentWeather}
                            language={this.props.language}
                        />
                    }

                    {!this.state.showFuture && <div className="later modeSwitchButton" onClick={()=>{
                                                    this.setState({showFuture: true})
                                                    this.props.setFutureMode(true, moment().month(0));
                                                }}>
                        <span>{labels.later[this.props.language]}</span>
                    </div>}

                </div>
            </Control>
        );
    }
}

export default DayPicker;