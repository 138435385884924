import React, { Component } from 'react';
import windArrowImg from '../../Icons/images/newWind.svg';
import './DayPicker.scss';
const iconSrc = require.context("../../Icons/images/weatherIcons/", true, /\.(png|svg|jpg|gif)$/);


class WeatherDisplay extends Component {

    constructor(props){
        super(props);
        this.state = {
            deg: 0,
            wind: 0,
            weatherIcon: '01d',
            temp: 0,
        }
        this.textRotation = this.textRotation.bind(this);
        this.updateWeather = this.updateWeather.bind(this);
    }
    componentWillReceiveProps(props){
        if(props.weather)
            this.updateWeather(props.weather);
    }

    textRotation = ()=>{
        return this.state.deg > 180 ? '90' : '-90';
    }

    updateWeather = (currentWeather)=>{
        this.setState({
            deg: (currentWeather.wind.deg-1) * 45,
            wind: currentWeather.wind.speed,
            weatherIcon: currentWeather.weather[0].icon,
            temp: Math.trunc(currentWeather.main.temp),
        });
    }

    render() {
        if(!this.props.futureMode && this.props.weather) //hvis ok
            return (
                <div className="weatherWidget">
                    <div style={{transform: 'rotate(' + this.state.deg + 'deg)'}} id="windContainer">
                        <img alt='' src={windArrowImg} style={{transform: 'rotate(-225deg)'}} id="windArrow" />
                        <p style={{transform: 'rotate(' + this.textRotation() + 'deg)'}}>
                            {(Math.round(this.state.wind*10)/10).toFixed(1) + 'm/s'}
                        </p>
                    </div>
                    <div id="weatherIcon">
                        <img src={iconSrc('./' + this.state.weatherIcon + '.png')} alt="" id="weatherDisplay" />
                    </div>
                    <div id="temperatureText">
                        <h1>{this.state.temp + '°C'}</h1>
                    </div>
                </div>
            );
        else            
            return (
                <div></div>
            );
    }
}

export default WeatherDisplay;