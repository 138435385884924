import React, { Component } from 'react';
import moment from 'moment';

const futureMonths = {
    da: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"],
    en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    de: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
}

const labels = {
    headline: {
        da: "Se hvilke pladser, der er i sæson",
        en: "See fishingspots in season",
        de: "Sehen Sie die Angelplätze in der Saison",
    },
    now: {
        da: "Nu",
        en: "Now",
        de: "Später",
    }
}

class FutureMonths extends Component {
    state={
        futureMonth: 1,
    };

    render() {
        let monthElements = futureMonths[this.props.language].map((m, i) => 
            <span key={i} onClick={()=>this.setState({futureMonth: i+1}, this.props.setFutureMode(true, moment().month(i)))} className={this.state.futureMonth===i+1 && "active"}>{m}</span>
        );
        return (
        <div>
            <div className="now modeSwitchButton" onClick={this.props.futureSwitch}>
                <span>{labels.now[this.props.language]}</span>
            </div>
            <div className="planner weatherDateContainer" data-bind="visible: futureMode">
                <div className="header">{labels.headline[this.props.language]}</div>
                <div className="body">
                    <div className="monthPicker">
                        <div className="months">
                            {monthElements}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default FutureMonths;