import React, { Component } from 'react';

const labels = {
    all: {
      da: "Alle",
      en: "All",
      de: "Alles"
    }
  }

class SettingsSelector extends Component {

    state = {
        value: labels.all[this.props.language],
    }

    handleChange(e) {
        this.setState({value: e.target.value}, () => {
            this.props.onChange(this.state.value);
        });
        
    }
  
    render() {
        const options = this.props.options.map((o, i) => <option key={i} value={o}>{o}</option>)

        return (
            <div className="settingsCard" style={enabledStyle}>
                <div className="card-body">
                    <h5 className="toggleTextLeft">{this.props.text}</h5>
                    <h4 className="toggleTextRight">{this.props.selected}</h4>
                    <select value={this.state.value} className="settingSelector" onChange={this.handleChange.bind(this)}>
                        {options}
                    </select>
                </div>
            </div>
        )
    }
}

const enabledStyle = {
    background: '#0000004d',
    opacity: '1',
}

export default SettingsSelector;
