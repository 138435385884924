import React, { Component } from "react";
import { Marker } from "react-leaflet";
import { certified } from "../Icons/icons";

class Overnatning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overnatning: []
    };
    fetch("https://www.limfjordsraadet.dk/data/overnatningssteder/").then(raw => raw.json()).then(res => {
        //console.log(res.data)
        const data = res.map(s => {
          s.facilities = {
            da: s.facilities,
            de: s.facilities_de,
            en: s.facilities_en
          };
          s.description = {
            da: s.description,
            de: s.descriptionDE,
            en: s.descriptionEN
          };
          s.type = "hotel";
          return s;
        });

        //console.log(data);

        this.setState({
          overnatning: data
        });
      }
    );
  }

  render() {
    const overnatning =
      this.state.overnatning &&
      this.state.overnatning.map((x, i) => (
        <Marker
          key={i}
          position={{ lat: x.gps[0].lat, lon: x.gps[0].long }}
          onclick={() => this.props.onClick(x)}
          icon={certified}
          zIndexOffset={50}
        />
      ));
    return <div>{overnatning}</div>;
  }
}

export default Overnatning;
