import Geolib from 'geolib';
import moment from 'moment';
import { WindFilter, SeasonFilter } from './Filters';

const labels = {
  all: {
    da: "Alle",
    en: "All",
    de: "Alles"
  }
}

export const filterSpots = (weather, allSpots, futureDate, futureMode, settings, language) => {
    // console.log(weather)
    let spots = allSpots.slice(0);
    //self.map.obj() && self.map.obj().spin(true);
    if(spots.length < 1 || !weather ) return;

    //console.log(futureDate)

    if(futureDate) spots = spots.filter((s) => SeasonFilter(s, moment(futureDate, "YYYY-MM-DD")));

    if(!futureMode && settings.windSelected) 
      spots = spots.filter((s) => WindFilter(s, weather));

    if(settings.seasonSelected && !futureDate) 
      spots = spots.filter((s) => SeasonFilter(s, moment()));

    if(settings.fishType && settings.fishType !== labels.all[language]) 
      spots = spots.filter((s) => s.fishTypes[language].some((f) => f === settings.fishType));

    if(settings.coastType && settings.coastType !== labels.all[language]) 
      spots = spots.filter((s) => s.coastType[language] === settings.coastType);



    return spots.slice(0).sort((a,b) => {
        if(a.distance > b.distance) return 1;
        else if(a.distance < b.distance) return -1;
        else return 0;
    });
  }

  export const spotDistanceFilter = (spots, yourLocation)=>{

        spots.forEach(s => s.distance = Math.round(Geolib.getDistance(
          {latitude: yourLocation.lat, longitude: yourLocation.lng}, 
          {latitude: s.gps[0].lat, longitude: s.gps[0].long}
        )/1000));
        //console.log(spots);

        //return spots;
  }