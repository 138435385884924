import React, { Component } from 'react';
import { LeafletConsumer } from 'react-leaflet';
import L from "leaflet";
import { tourist } from '../Icons/icons'

L.esri = require('esri-leaflet');

const labels = {
  touristOffice: {
    da: "Turistkontor",
    en: "Tourist office",
    de: "Touristeninformation",
  },
  seeWebsite: {
    da: "Besøg hjemmeside",
    en: "See website",
    de: "Siehe Website",
  }
}

class TouristOffices extends Component {
  constructor(props) {
    super(props)
    this.layer = L.esri.featureLayer({ //pladser fra gis
      url: 'https://gis.aalborg.dk/ak5/rest/services/MiljoePub/LIMF_Turistkontor/MapServer/0',
      pointToLayer(feature, latlng) {
          const layer = new L.Marker(latlng, {
            icon: tourist,
            zIndexOffset: 50
          });
          return layer;
      },
    });
  }
  
  setContext(context) {
    this.context = context;
  }

  componentDidMount() {
    this.layer.bindTooltip((layer) => { return layer.feature.properties.TURISTKONT})
        .bindPopup((layer) => { return `${labels.touristOffice[this.props.language]}  ${layer.feature.properties.TURISTKONT} <br /><a href="https://${layer.feature.properties.LINK}" target="_blank">${labels.seeWebsite[this.props.language]}</a>`});
    this.layer.addTo(this.context.map);
  }

  componentWillUnmount() {
    this.context.map.removeLayer(this.layer)
  }

  render() {
    return (
      <LeafletConsumer>
        {context => this.setContext(context)}
      </LeafletConsumer>
    )
  }
}

export default TouristOffices;