import React from 'react'
import './Sidebar.scss'


class Sidebar extends React.Component {

  onClose(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClose && this.props.onClose(this.props.position);
  }

  onOpen(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onOpen && this.props.onOpen();
  }
  componentWillReceiveProps(props){
    if(!props.collapsed && this.props.collapsed){//hvis den går fra collapsed til open
      this.props.onOpen();
    } 
  }

  // Override render() so the <Map> element contains a div we can render to
  render() {
    const position = ' sidebar-' + (this.props.position || 'left');
    const collapsed = this.props.collapsed ? ' collapsed' : '';

    return (
      <div id={this.props.id} className={"sidebar leaflet-touch" + position + collapsed}
        ref={el => this.rootElement = el}>
        <div  className="sidebarInner">
          <div className="close" onClick={this.onClose.bind(this)}>×</div>
          {this.props.children}
        </div>

      </div>
    );
  }
}

export default Sidebar;