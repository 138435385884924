import React, { Component } from "react";
import { LeafletConsumer } from "react-leaflet";
import L from "leaflet";
L.esri = require("esri-leaflet");

class GoogleMap extends Component {
  googleMap = L.tileLayer(
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
    {
      maxZoom: 18
    }
  );

  //"https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}"

  setContext(context) {
    this.context = context;
  }

  componentDidMount() {
    this.googleMap.addTo(this.context.map);
  }

  componentWillUnmount() {
    this.context.map.removeLayer(this.googleMap);
  }

  render() {
    return (
      <LeafletConsumer>{context => this.setContext(context)}</LeafletConsumer>
    );
  }
}

export default GoogleMap;
