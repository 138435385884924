import React, { Component } from 'react';
import { LeafletConsumer } from 'react-leaflet';
import L from "leaflet";
import { iconLowSmall, iconHighSmall } from '../Icons/icons'

L.esri = require('esri-leaflet');

class Fiskespots extends Component {
  constructor(props) {
    super(props)
    const self = this;
    this.layer = L.esri.featureLayer({ //pladser fra gis
      url: 'https://gis.aalborg.dk/ak5/rest/services/MiljoePub/LIMF_Fiskepladser/MapServer/0',
      pointToLayer(feature, latlng) {
          const layer = new L.Marker(latlng, {
            icon: iconLowSmall,
          }).on('click', (e) => {
              self.props.onClick && self.props.onClick(layer);
          });
          return layer;
      },
    })
  }
  
  setContext(context) {
    this.context = context;
  }

  componentDidUpdate() {
    this.layer.eachFeature((feature) => {
      feature.setIcon(this.props.filteredSpots.some((f) => feature.feature.properties.NY_ID === f.id) ? iconHighSmall : iconLowSmall)
    })
  }

  componentDidMount() {
    this.layer.on("load", () => {
      this.layer.eachFeature((feature) => {
        feature.setIcon(this.props.filteredSpots.some((f) => feature.feature.properties.NY_ID === f.id) ? iconHighSmall : iconLowSmall)
      })
    })
    this.layer.bindTooltip((layer) => { return layer.feature.properties.NAVN})
    this.layer.addTo(this.context.map);
  }

  componentWillUnmount() {
    this.context.map.removeLayer(this.layer)
  }

  render() {
    return (
      <LeafletConsumer>
        {context => this.setContext(context)}
      </LeafletConsumer>
    )
  }
}

export default Fiskespots;