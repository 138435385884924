import React, { Component } from 'react';
import moment from 'moment';

const labels = {
    title: {
        da: "Planlæg din fisketur",
        en: "Plan your fishingtrip",
        de: "Planen Sie Ihre Angeltour",
    },
    days: {
        da: ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"],
        en: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        de: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    }
}

class PickContainer extends Component {

    constructor(props){
        super(props);

        this.selectDay = this.selectDay.bind(this);
        this.selectedTime = this.selectedTime.bind(this);
        this.setSelectedDayHours = this.setSelectedDayHours.bind(this);

        let days = []; //sæt navne på dage:
        for(let i = 0, j = 0; i<5; i++) {
            i === 0 && parseInt(moment().format("HH")) >= 21 && j++; //fjern i dag, hvis tid over 21
            let dayNr = moment().add(j, "days").day();
            dayNr = dayNr === 0 ? 6 : dayNr-1;
            let name = {
                da: j === 0 ? "I dag" : labels.days.da[dayNr],
                en: j === 0 ? "Today" : labels.days.en[dayNr],
                de: j === 0 ? "Heute" : labels.days.de[dayNr],
            };

            days[i] = {
                moment: moment().add(j, "days"),
                name: name,
            }
            j++;
        }
        let selectedDay = days[0];
        let selectedDayHours = this.setSelectedDayHours(selectedDay);

        this.state={
            days: days, //dagnavne 
            selectedDay: selectedDay, //valgte dag
            selectedDayHours: selectedDayHours,  //timerne der er vejrdata på for den valgte dag
            selectedTime: selectedDayHours[0], //valgte tidspunkt
        }


    }
    
    setSelectedDayHours(day){
        let selectedDayHours = []; //sæt timerne som loades:
        if(this.props.weather){
            selectedDayHours = this.props.weather.filter(w => day.moment.isSame(w.dt_txt, "day")).map((w) => {
                return moment(w.dt_txt).format("HH")
            });
        }
        return selectedDayHours;
    }

    selectedTime(time){ //Nyt tidspunkt valgt
        // console.log(time);
        let curW = this.props.weather.find(
            w => {
                return  this.state.selectedDay.moment.isSame(w.dt_txt, "day")
                        && time === moment(w.dt_txt).format("H")
            }
        );
        this.props.updateCurrentWeather(curW);
    }

    selectDay(newDay){ //ny dag valgt
        let selectedDayHours = this.setSelectedDayHours(newDay);
        
        let time = parseInt(selectedDayHours[0], 10).toString();//fjern leading 0's

        this.setState({
            selectedDay: newDay, //valgte dag
            selectedDayHours: selectedDayHours,  //timerne der er vejrdata på for den valgte dag
            selectedTime: selectedDayHours[0], //valgte tidspunkt
        },()=>{this.selectedTime(time)});
    }


    render() {
        let days = [];
        this.state.days.forEach((day, i) => {
            days.push(
                <div key={i} className={this.state.selectedDay === day ? "day selected" : "day"} onClick={() => this.selectDay(day)}>
                    <span>{day.name[this.props.language]}</span>
                </div>
            );
        });
        let hourLabels = [];
        this.state.selectedDayHours.forEach((hour, i)=>{
            hourLabels.push( 
                <li key={i} className="active selected">{hour}</li>
            );
        })                    

        return (
            <div className="planner weatherDayContainer">
                <div className="header">{labels.title[this.props.language]}</div>
                <div className="body">
                    <div className="daysContainer">
                        <div className="weatherDay">
                            {days}
                        </div>
                        <div className="slidecontainer">
                            <div className="range">
                                <input
                                    type="range" 
                                    min={this.state.selectedDayHours[0]}
                                    max={this.state.selectedDayHours[this.state.selectedDayHours.length-1]}
                                    value={this.state.selectedTime}
                                    style={{
                                        width: (this.state.selectedDayHours.length * 40)  + 'px',
                                        left: '50%',
                                        marginLeft: '-' + this.state.selectedDayHours.length * 40 / 2 + 'px',
                                    }}
                                    onChange={(value)=>{
                                        this.setState({selectedTime: value.target.value});
                                        this.selectedTime(value.target.value);
                                    }}
                                    className="slider" 
                                    id="myRange" 
                                    step="3" />
                            </div>
                            <ul className="range-labels" style={{
                                width: (this.state.selectedDayHours.length * 40) + 'px',
                                left: '50%',
                                marginLeft: '-' + this.state.selectedDayHours.length * 40 / 2 + 'px',
                            }}>
                                {hourLabels}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PickContainer;