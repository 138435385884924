import React, { Component } from 'react';
import './SidebarContent.scss'

const labels = {
    email: {
        da: "Email",
        en: "Email",
        de: "Email",
    },
    phone: {
        da: "Telefon",
        en: "Telephone",
        de: "Telefon",
    },
    openingHours: {
        da: "Åbningstider",
        en: "Opening hours",
        de: "Öffnungszeiten"
    },
    facilities: {
        da: "Faciliteter",
        en: "Facilities",
        de: "Anlagen",
    },
    seeWebpage: {
        da: "Se hjemmeside",
        en: "See website",
        de: "Siehe Website"
    },
    bookAccomodation: {
        da: "Læs mere",
        en: "Read more",
        de: "Weiterlesen",
    }

}

class SidebarContent extends Component {
  state = {
  }
  constructor(props){
    super(props);
    this.getWebsite = this.getWebsite.bind(this);
  }

  componentDidMount() {
  }
  getWebsite(){
    let url = this.props.data.website
    if (url && !/^(f|ht)tps?:\/\//i.test(url)) {
      url = "http://" + url;
    }
    return url;
  }
  
  render() {
    let buttonText = labels.seeWebpage[this.props.language];

    if(this.props.data.type === "hotel")
        buttonText = labels.bookAccomodation[this.props.language];


    

    const facilities = this.props.data.facilities[this.props.language].map((f, i) => <li key={i}>{f}</li>)
    
    return (
        <div style={{position: "relative", height: "100%", width: "100%"}}>
            <div id="certifiedHotel">
                <div className="header clearfix">
                    <div>
                        <h2 className="overskrift">{this.props.data.name}</h2>
                    </div>

                    <div className="headlineBar">
                        <span class="label">{labels.email[this.props.language]}</span> 
                        <a href={'mailto:' + this.props.data.eMail}><span class="data">{this.props.data.eMail}</span></a>
                    </div>
                    <div className="headlineBar">
                        <span class="label">{labels.phone[this.props.language]}</span> 
                        <span class="data">{this.props.data.telephone}</span>
                    </div>
                    <div className="headlineBar">
                        <span class="label">{labels.openingHours[this.props.language]}</span> 
                        <span class="data">{this.props.data.openingHours}</span>
                    </div>

                    <div>
                        <img src={this.props.data.picture} alt="Billede af hotel" className="hotelImage" />
                    </div>

                    <div>
                        <p className="description">{this.props.data.description[this.props.language]}</p>
                    </div>
                    <div style={{display: this.props.data.facilities[this.props.language].length > 0 ? "block" : "none"}} className="facilities">
                        <h3>{labels.facilities[this.props.language]}:</h3>
                        <div>
                            <ul>
                                {facilities}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttonContainer">
                <a href={this.getWebsite()} target="_blank" rel='noreferrer'>
                    <button class="navButton">{buttonText}</button>
                </a>
            </div>
        </div>
    )
  }
}

export default SidebarContent;
