import React, { Component } from 'react';
import SettingsToggle from './SettingsToggle';
import SettingsSelector from './SettingsSelector';

const labels = {
    wind: {
        da: "Vind",
        en: "Wind",
        de: "Wind",
    },
    coastType: {
        da: "Kysttype",
        en: "Coastal type",
        de: "Küstentyp",
    },
    season: {
        da: "Sæson",
        en: "Season",
        de: "Saison"
    },
    fishTypes: {
        da: "Fiskearter",
        en: "Fish species",
        de: "Fischarten",
    },
    windAlert: {
        da: "Du kan ikke slå vind til, når du planlægger længere frem",
        en: "You can't calculate by wind when you plan ahead",
        de: "Sie können nicht mit Wind rechnen, wenn Sie vorausplanen",
    },
    lessThanFive: {
        da: 'Filterer ikke efter vindretning, når vindhastigheden er under 5 m/s',
        en: 'Does not filter by wind direction when the wind speed is below 5 m/s',
        de: "Nicht in Windrichtung filtern, wenn die Windgeschwindigkeit unter 5 m / s liegt.",
    },
    cantEnableSeason: {
        da: 'Du kan ikke slå sæson til, når du planlægger længere frem',
        en: 'You can\'t turn on season on when you plan ahead',
        de: "Sie können die Saison nicht einschalten, wenn Sie im Voraus planen",
    }
}

class Settings extends Component {
    constructor(props) {
        super(props);

        this.handleWind = this.handleWind.bind(this);
        this.handleSeason = this.handleSeason.bind(this);
        this.handleFishType = this.handleFishType.bind(this);
        this.handleCoastType = this.handleCoastType.bind(this);
    }

    handleWind() {
        if(this.props.futureMode) {
            alert(labels.windAlert[this.props.language])
            return;
        }
        if(this.props.windSpeed < 5) {
            alert(labels.lessThanFive[this.props.language]);
            return;
        }
        let settings = this.props.settings;
        settings.windSelected = !settings.windSelected;
        this.props.newSettings(settings);
    }

    handleSeason() {
        if(this.props.futureMode) {
            alert(labels.cantEnableSeason[this.props.language])
            return;
        }
        let settings = this.props.settings;
        settings.seasonSelected = !settings.seasonSelected;
        this.props.newSettings(settings);
    }

    handleFishType(fisk){

        let settings = this.props.settings;
        settings.fishType = fisk;
        this.props.newSettings(settings);
    }

    handleCoastType(coast){
        let settings = this.props.settings;
        settings.coastType = coast;
        this.props.newSettings(settings);
    }

    componentWillReceiveProps(props){
        //console.log(props.futureMode);
    }
  
    render() {
        return (
            <div className="settings">
                <SettingsToggle
                    onClick={this.handleWind}
                    enabled={this.props.settings.windSelected && !this.props.futureMode && this.props.windSpeed >= 5}
                    text={labels.wind[this.props.language]}
                    info={this.props.futureMode ? "<5m/s" : undefined}
                    language={this.props.language} />

                <SettingsToggle
                    onClick={this.handleSeason}
                    enabled={this.props.settings.seasonSelected && !this.props.futureMode}
                    text={labels.season[this.props.language]}
                    language={this.props.language} />   

                <SettingsSelector 
                    text={labels.coastType[this.props.language]}
                    onChange={this.handleCoastType}
                    selected={this.props.settings.coastType}
                    options={this.props.coastTypes}
                    language={this.props.language} />

                <SettingsSelector 
                    text={labels.fishTypes[this.props.language]}
                    selected={this.props.settings.fishType}
                    onChange={this.handleFishType}
                    options={this.props.fishTypes}
                    language={this.props.language} />
            </div>
        )
    }
}

export default Settings;
