import React, { Component } from 'react';
import Control from 'react-leaflet-control'
import './MapPicker.scss'

class MapPicker extends Component {
  
    render() {
        return (
            <Control className="mapPickerContainer" position="bottomright">
                {this.props.children}
            </Control>
        )
    }
}

export default MapPicker;
