import React, { Component } from 'react';

const labels = {
  on: {
    da: "Til",
    en: "On",
    de: "An"
  },
  off: {
    da: "Fra",
    en: "Off",
    de: "Aus"
  }
}

class SettingsToggle extends Component {
  
  render() {
    return (
        <div className="settingsCard" onClick={this.props.onClick} style={this.props.enabled ? enabledStyle : disabledStyle}>
          <div className="card-body">
            <h5 className="toggleTextLeft">{this.props.text}</h5>
            <h4 className="toggleTextRight">{this.props.info ? this.props.info : this.props.enabled ? labels.on[this.props.language] : labels.off[this.props.language]}</h4>
          </div>
        </div>
    )
  }
}

const enabledStyle = {
    background: '#0000004d',
    opacity: '1',
}

const disabledStyle = {
    background: '#C2C2C24d',
    opacity: '0.5',
}

export default SettingsToggle;
