import React, { Component } from "react";
import Dybdekort from "./Features/Maps/Dybdekort";
import Satelite from "./Features/Maps/Satelite";
import DepthLegend from "./UIComponents/DepthLegend";
import Layer from "./UIComponents/LayerPick/Layer";
import MapPicker from "./UIComponents/MapPicker/MapPicker";
import GoogleMap from "./Features/Maps/GoogleMap";

const labels = {
  street: {
    da: "Kort",
    en: "Street Map",
    de: "Straßenkarte"
  },
  satelite: {
    da: "Satellit",
    en: "Satellite",
    de: "Satelliten"
  },
  depth: {
    da: "Dybdekort",
    en: "Depth Map",
    de: "Tiefenkarte"
  }
};

class MapTiles extends Component {
  state = {
    street: true,
    satelite: false,
    showDepth: false
  };
  render() {
    return (
      <div>
        <MapPicker>
          <div style={{ display: this.props.display || "none" }}>
            <Layer
              className="mapPicker"
              text={labels.street[this.props.language]}
              active={this.state.street && !this.state.showDepth}
              onClick={() =>
                this.setState({
                  street: true,
                  satelite: false,
                  showDepth: false
                })
              }
            />
            <Layer
              className="mapPicker"
              text={labels.satelite[this.props.language]}
              active={this.state.satelite}
              onClick={() =>
                this.setState({
                  satelite: true,
                  street: false,
                  showDepth: false
                })
              }
            />
            <Layer
              className="mapPicker"
              text={labels.depth[this.props.language]}
              active={this.state.showDepth}
              onClick={() =>
                this.setState({
                  showDepth: true,
                  street: true,
                  satelite: false
                })
              }
            />
          </div>
        </MapPicker>

        {this.state.street && <GoogleMap />}
        {this.state.satelite && <Satelite />}
        {/*this.state.street && (
          <Rectangle
            bounds={[[57.816795, 5.059151], [54.428419, 7.640424]]}
            color={"#94C1E1"}
            opacity={1}
            fill={true}
            fillOpacity={1}
          />
        )*/}
        {this.state.showDepth && <Dybdekort />}
        {this.state.showDepth && <DepthLegend />}
      </div>
    );
  }
}

export default MapTiles;
