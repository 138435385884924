import React, { Component } from 'react';
import Control from 'react-leaflet-control'
import './LayerPicker.scss'

class LayerPicker extends Component {
    state = {
        
    }

    componentDidMount() {
   
    }
  
    render() {
        //const pickers = this.props.pickers.map((p) => <div onClick={() => this.props.onClick(p)} className="layerPicker">{p}</div>)
        return (
            <Control style={this.props.style} className="layerPickerContainer" position="topright">
                {this.props.children}
            </Control>
        )
    }
}

export default LayerPicker;
